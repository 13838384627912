import DatePicker from 'react-date-picker';
import { useTranslation } from 'react-i18next';

import useClasses from '@/components/layout/hooks/useClasses';
import CustomButton from '@/components/inputs/CustomButton';

const styles = {
  wrapper: {
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  datepicker: {
    '&>div': {
      padding: '0.3rem',
      borderRadius: '0.3rem',
      borderColor: 'lightgrey',
    },
    '&>span>*': {
      position: 'absolute',
    },
    height: '100%',
    alignSelf: 'stretch',
    marginLeft: '0.5rem',
  },
};

const DateSelector = ({
  startDate, setStartDate, endDate, setEndDate, handleSubmit,
}) => {
  const { t } = useTranslation();

  const classes = useClasses(styles);

  return (
    <div className={classes.wrapper}>
      <div>
        {t('startDate')}
        <DatePicker className={classes.datepicker} value={startDate} onChange={setStartDate} maxDate={endDate} />
      </div>
      <div>
        {t('endDate')}
        <DatePicker className={classes.datepicker} value={endDate} onChange={setEndDate} minDate={startDate} />
      </div>
      <CustomButton onClick={handleSubmit}>{t('update')}</CustomButton>
    </div>
  );
};
export default DateSelector;
