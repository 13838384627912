import {
  IconButton,
  MenuItem,
  Menu,
} from '@mui/material';
import {
  AccountCircle,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import Authorized from '@/screens/auth/Authorized';
import CustomLink from '@/components/inputs/CustomLink';
import i18n from '@/components/translations/i18n';
import useClasses from '@/components/layout/hooks/useClasses';
import UrlEnums from '@/components/connections/enums/UrlEnums';

const styles = () => ({
  sectionDesktop: {
    display: 'flex',
    // [theme.breakpoints.up('md')]: {
    //   display: 'flex',
    // },
  },
});

const menuId = 'primary-search-account-menu';

const menu = [
  {
    component:
      ({ onClick }) => (
        <IconButton
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          color="inherit"
          onClick={onClick}
          size="large"
        >
          <AccountCircle />
        </IconButton>
      ),
    onClick: 'handleProfileMenuOpen',
    text: i18n.t('profile'),
  },
];

const ProfileMenu = ({
  anchorEl,
  handleMenuClose,
}) => {
  const isMenuOpen = Boolean(anchorEl);
  const { t } = useTranslation();
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <div>
        <Authorized authenticated>
          <CustomLink plain to={UrlEnums.PROFILE}>
            <MenuItem onClick={handleMenuClose}>{t('account.my')}</MenuItem>
          </CustomLink>
          <CustomLink plain to={UrlEnums.LOGOUT}>
            <MenuItem onClick={handleMenuClose}>{t('logout')}</MenuItem>
          </CustomLink>
        </Authorized>
        <Authorized publicOnly>
          <CustomLink plain to={UrlEnums.LOGIN}><MenuItem onClick={handleMenuClose}>{t('login')}</MenuItem></CustomLink>
        </Authorized>
      </div>
    </Menu>
  );
};

const DesktopMenuSection = props => {
  const classes = useClasses(styles);
  return (
    <div className={classes.sectionDesktop}>
      {menu.map((el, ind) => {
        const { component: Component, onClick, authorizations } = el;
        const { [onClick]: onClickFunction } = props;
        let WrappedComponent = onClick
          ? <Component key={`${ind}_${1}`} onClick={onClickFunction} />
          : <Component key={`${ind}_${1}`} />;
        if (authorizations) {
          WrappedComponent = (
            <Authorized key={`${ind}_${0}`} {...authorizations}>
              {WrappedComponent}
            </Authorized>
          );
        }
        return WrappedComponent;
      })}
    </div>
  );
};

export { ProfileMenu, DesktopMenuSection, menu };
