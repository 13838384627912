import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import Connections, { ApiEndpoints } from '@/components/connections/Connections';

import DateSelector from './DateSelector';
import ResourceStatsTable from './ResourceStatsTable';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const oneMonthAgo = () => {
  const d = new Date();
  const month = d.getMonth();
  d.setMonth(d.getMonth() - 1);
  d.setHours(0, 0, 0, 0);
  if (d.getMonth() === month) {
    d.setDate(0);
  }
  return d;
};

const ResourceReport = () => {
  const { t } = useTranslation();
  const { resourceId, type, groupId } = useParams();
  const [viewsChartData, setViewsChartData] = useState([]);
  const [sentChartData, setSentChartData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState(oneMonthAgo());
  const [endDate, setEndDate] = useState(new Date());
  const [resourceName, setResourceName] = useState('');

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Video Views',
        data: viewsChartData,
        borderColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'Videos Sent',
        data: sentChartData,
        borderColor: 'rgb(99, 172, 255)',
      },
    ],
  };

  const fetchReport = async () => {
    const res = await Connections.postRequest(ApiEndpoints.getResourceReport, {
      resourceId, type, startDate, endDate, groupId,
    });
    if (res.ok) {
      const { viewsData, name } = res.data;
      setResourceName(name);
      setViewsChartData(viewsData.map(el => el.views));
      setSentChartData(viewsData.map(el => el.sent));
      let newLabels;
      if (viewsData?.[0].date) {
        const formatter = new Intl.DateTimeFormat('de', { day: '2-digit', month: '2-digit', year: '2-digit' });
        newLabels = viewsData.map(el => formatter.format(Date.parse(el.date)));
      } else {
        const formatter = new Intl.DateTimeFormat('default', { month: 'long', year: 'numeric' });
        newLabels = viewsData.map(el => {
          const newDate = new Date(startDate.getTime());
          newDate.setMonth(startDate.getMonth() + el.monthIndex);
          return formatter.format(newDate);
        });
      }
      setLabels(newLabels);
      setTableData(
        viewsData
          .map((el, i) => ({ ...el, label: newLabels[i] }))
          .filter(el => (el.views > 0 || el.sent > 0)),
      );
    }
  };

  useEffect(() => {
    fetchReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h1" sx={{ margin: '1rem 0' }}>
        {t('reports')} - {resourceName}
      </Typography>
      <Line data={data} options={options} />
      <DateSelector
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        handleSubmit={fetchReport}
      />
      <ResourceStatsTable logs={tableData} />
    </>
  );
};
export default ResourceReport;
