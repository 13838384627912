import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CustomLink from '@/components/inputs/CustomLink';

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <CustomLink
      rel="noreferrer"
      plain
      target="_blank"
      href="https://easyfit-app.de"
    >
      easyFIT APP
    </CustomLink>{' '}
    {new Date().getFullYear()}
    .
  </Typography>
);
const Sponsoring = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t('footer.sponsor')}
    </Typography>
  );
};

const Footer = () => (
  <Container sx={{ justifySelf: 'end', mb: 1 }}>
    <Box mt={8}>
      <Copyright />
      <Sponsoring />
    </Box>
  </Container>
);
export default Footer;
