const UrlEnums = {
  MAIN: '/',
  PROFILE: '/profile',
  ADMIN: '/admin',
  LOGIN: '/login',
  LOGOUT: '/logout',
  VERIFY: '/verify/:verificationToken',
  PASSWORD_FORGET: '/forgetPassword',
  SHOWCASE: '/showcase',
  NOT_FOUND: '/notFound',
  USERS_ALL: '/users/all',
  USERS_ADMIN: '/users/admin',
  API_KEYS_ALL: '/apiKeys/all',
  RESOURCES_ADD: '/resources/add',
  NEW_USER: '/signUp/:groupId',
  WORKOUT: '/workouts/:groupId/:bundleId',
  WORKOUT_EXERCISE: '/workouts/:groupId/:bundleId/:resourceId',
  WORKOUT_COMPLETE: '/workouts/:groupId/:bundleId/complete',

  GROUPS_BY_ID: '/groups/:groupId',
  GROUPS_ALL: '/groups',
  CONTENT_PROVIDERS: '/contentProviders',
  CUSTOMER_COMPANIES: '/groups/:groupId/customerCompanies',
  GROUPS_CATEGORIES: '/groups/:groupId/categories',
  GROUPS_USERS: '/groups/:groupId/users',
  USER_EDIT: '/groups/:groupId/users/:clientNumber',
  RESOURCES_BY_CATEGORY: '/groups/:groupId/categories/:categoryKey/resources',
  REPORTING_ALL: '/reports',
  REPORTING: '/reports/:groupId/:type/:resourceId',
};
export default UrlEnums;
