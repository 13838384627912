import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Typography } from '@mui/material';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import { version } from '../package.json';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
    <Typography sx={{ textAlign: 'center', fontSize: 12, color: 'darkgray' }}>
      Version: {version}
    </Typography>
  </StrictMode>,
);

serviceWorkerRegistration.register();
