import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import useClasses from '@/components/layout/hooks/useClasses';

const styles = theme => ({
  tableHead: {
    // backgroundColor: '#5B9BD5',
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    '&>th': {
      color: theme.palette.primary.contrastText,
    },
  },
  tableRow: {
    backgroundColor: '#D2DEEF',
    '&:nth-of-type(odd)': {
      backgroundColor: '#EAEFF7',
    },
  },
});

const ResourceStatsTable = ({ logs }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const formatter = new Intl.DateTimeFormat('default', { dateStyle: 'full' });

  return (
    <Table>
      <TableHead>
        <TableRow className={classes.tableHead}>
          <TableCell>{t('date')}</TableCell>
          <TableCell>{t('views')}</TableCell>
          <TableCell>{t('sent')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {logs.map((entry, index) => (
          <TableRow className={classes.tableRow} key={`${entry.date}${index}`}>
            <TableCell>{entry.date ? formatter.format(Date.parse(entry.date)) : entry.label}</TableCell>
            <TableCell>{entry.views}</TableCell>
            <TableCell>{entry.sent}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default ResourceStatsTable;
