import { useEffect } from 'react';

import { registerPushNotifications, unregisterPushNotifications } from '../../serviceWorkerRegistration';

let pushNotificationsActivated = false;

const timeoutPromise = timeout => new Promise(resolve => {
  setTimeout(resolve, timeout);
});

const useRegisterPushNotifications = () => {
  useEffect(() => {
    const handleSWMessage = event => {
      if (event.data.code === 'activated') {
        pushNotificationsActivated = true;
        console.info('%cPush notifications enabled', 'color: green');
      }
    };
    navigator?.serviceWorker?.addEventListener('message', handleSWMessage);

    return () => { navigator.serviceWorker?.removeEventListener('message', handleSWMessage); };
  }, []);

  const registerPN = async (forceNewSubscription = false) => {
    // first timeout required to let recoil set up
    await timeoutPromise(500);
    if (!pushNotificationsActivated) {
      await registerPushNotifications(forceNewSubscription);
      await timeoutPromise(4000);
    }
    if (!pushNotificationsActivated) {
      console.warn('First PN subscription failed, retrying');
      await unregisterPushNotifications();
      await registerPushNotifications(forceNewSubscription);
      await timeoutPromise(2000);
    }
    return pushNotificationsActivated;
  };

  const unregisterPN = async () => {
    await unregisterPushNotifications();
    pushNotificationsActivated = false;
  };

  return { registerPN, unregisterPN };
};

export default useRegisterPushNotifications;
