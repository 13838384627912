import {
  Navigate,
} from 'react-router-dom';
import React from 'react';

import UrlEnums from '@/components/connections/enums/UrlEnums';
import { UserRoles } from '@/screens/users/enums/UserEnums';
import ResourceReport from '@/screens/reporting/ResourceReport/ResourceReport';

const Report = React.lazy(() => import('@/screens/reporting/Report'));
const Home = React.lazy(() => import('@/screens/Home'));
const Logout = React.lazy(() => import('@/screens/auth/Logout'));
const NotFoundPage = React.lazy(() => import('@/screens/NotFoundPage'));
const Login = React.lazy(() => import('@/screens/auth/Login'));
const AllUsers = React.lazy(() => import('@/screens/users/AllUsers'));
const EditUser = React.lazy(() => import('@/screens/users/EditUser'));
const AllApiKeys = React.lazy(() => import('@/screens/apiKeys/AllApiKeys'));
const Resources = React.lazy(() => import('@/screens/resources/Resources'));
const EditCategories = React.lazy(() => import('@/screens/categories/EditCategories'));
const Onboarding = React.lazy(() => import('@/screens/newUser/Onboarding'));
const Workout = React.lazy(() => import('@/screens/workouts/Workout'));
const WorkoutComplete = React.lazy(() => import('@/screens/workouts/WorkoutComplete'));
const Exercise = React.lazy(() => import('@/screens/workouts/Exercise'));
const ContentProviders = React.lazy(() => import('@/screens/groups/ContentProviders/ContentProviders'));
const CustomerCompanies = React.lazy(() => import('@/screens/groups/CustomerCompanies/CustomerCompanies'));
const MyAccount = React.lazy(() => import('@/screens/myAccount/MyAccount'));
const AdminUsers = React.lazy(() => import('@/screens/users/adminUsers/AdminUsers'));
const Verify = React.lazy(() => import('@/screens/auth/Verify'));

const routes = [
  {
    path: UrlEnums.MAIN, element: <Home />, type: 'authenticated',
  },
  {
    path: UrlEnums.PROFILE, element: <MyAccount />, type: 'authenticated',
  },
  { path: UrlEnums.LOGIN, element: <Login />, type: 'public' },
  // { path: UrlEnums.PASSWORD_FORGET, element: <ForgetPassword /> },
  { path: UrlEnums.LOGOUT, element: <Logout />, type: 'authenticated' },
  { path: UrlEnums.VERIFY, element: <Verify /> },
  {
    path: UrlEnums.API_KEYS_ALL, element: <AllApiKeys />, type: 'authorized', authProps: { adminOnly: true },
  },
  {
    path: UrlEnums.USER_EDIT, element: <EditUser />, type: 'authorized', authProps: { groupAdmin: true },
  },
  /* {
    path: UrlEnums.GROUPS_ALL, element: <Groups />, type: 'authorized', authProps: { groupAdmin: true },
  }, */
  {
    path: UrlEnums.CONTENT_PROVIDERS,
    element: <ContentProviders />,
    type: 'authorized',
    authProps: { adminOnly: true },
  },
  {
    path: UrlEnums.CUSTOMER_COMPANIES,
    element: <CustomerCompanies />,
    type: 'authorized',
    authProps: { allowedRoles: [UserRoles.ADMIN, UserRoles.CONTENT_PROVIDER_ADMIN] },
  },
  {
    path: UrlEnums.GROUPS_CATEGORIES, element: <EditCategories />, type: 'authenticated',
  },
  {
    path: UrlEnums.RESOURCES_BY_CATEGORY, element: <Resources />, type: 'authenticated',
  },
  {
    path: UrlEnums.GROUPS_USERS, element: <AllUsers />, type: 'authenticated',
  },
  {
    path: UrlEnums.USERS_ADMIN, element: <AdminUsers />, type: 'authorized', authProps: { adminOnly: true },
  },
  {
    path: UrlEnums.NEW_USER, element: <Onboarding />, type: 'public',
  },
  {
    path: UrlEnums.WORKOUT, element: <Workout />, type: 'authenticated',
  },
  {
    path: UrlEnums.WORKOUT_EXERCISE, element: <Exercise />, type: 'authenticated',
  },
  {
    path: UrlEnums.WORKOUT_COMPLETE, element: <WorkoutComplete />, type: 'authenticated',
  },
  {
    path: UrlEnums.REPORTING_ALL,
    element: <Report />,
    type: 'authorized',
    authProps: { allowedRoles: [UserRoles.ADMIN, UserRoles.CONTENT_PROVIDER_ADMIN] },
  },
  {
    path: UrlEnums.REPORTING,
    element: <ResourceReport />,
    type: 'authorized',
    authProps: { allowedRoles: [UserRoles.ADMIN, UserRoles.CONTENT_PROVIDER_ADMIN] },
  },
  { path: UrlEnums.NOT_FOUND, element: <NotFoundPage /> },
  { path: '*', element: <Navigate to={UrlEnums.NOT_FOUND} replace /> },
];

export default routes;
