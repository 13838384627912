import { UserGroupRoles, UserRoles } from '@/screens/users/enums/UserEnums';

const AuthHelper = {
  isAuthorized(userData, authorizations) {
    const {
      authenticated,
      adminOnly,
      groupAdmin,
      contentProviderAdminOnly,
      customerCompanyAdminOnly,
      publicOnly,
      allowedRoles,
      allowedPermissions,
    } = authorizations;

    const {
      loggedIn,
      isAdmin,
      role,
      permissions,
      groups,
    } = userData;

    // Check for public only
    if (publicOnly && (loggedIn || isAdmin)) return false;

    // Check for roles
    if (allowedRoles
      && allowedRoles.length
      && !allowedRoles.some(r => role === r)) { return false; }

    // Check for permissions
    if (allowedPermissions
      && allowedPermissions.length
      && !allowedPermissions.some(r => permissions.includes(r))) { return false; }

    // check if content provider admin
    if (contentProviderAdminOnly && role !== UserRoles.CONTENT_PROVIDER_ADMIN) return false;

    // check if customer company admin
    if (customerCompanyAdminOnly
      && role !== UserRoles.CUSTOMER_COMPANY_ADMIN
      && role !== UserRoles.CONTENT_PROVIDER_ADMIN
    ) return false;

    // Check if group admin
    if (groupAdmin && !groups?.find(
      g => g.role === UserGroupRoles.OWNER || g.role === UserGroupRoles.ADMIN,
    ) && !isAdmin) return false;

    // Check if admin
    if (adminOnly && !isAdmin) return false;

    // Check for registered only
    return !(authenticated && !loggedIn);
  },
};

export default AuthHelper;
