const UserEnums = {
  ADMIN_ROLE: 'admin',
  USER_ROLE: 'user',
};

export const UserRoles = {
  ADMIN: 'admin',
  CONTENT_PROVIDER_ADMIN: 'contentProviderAdmin',
  CUSTOMER_COMPANY_ADMIN: 'customerCompanyAdmin',
  USER: 'user',
};

export const UserGroupRoles = {
  OWNER: 'owner',
  ADMIN: 'admin',
  USER: 'user',
};

export const UserStatuses = [
  'active',
  'inactive',
  'banned',
];

export default UserEnums;
