import { Close } from '@mui/icons-material';
import {
  Alert, Button, IconButton, Snackbar,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useRegisterPushNotifications from '@/components/serviceWorker/useRegisterPushNotifications';

import { useLoggedIn } from './users/hooks/userDataHooks';

const isSupported = () => 'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

const Notification = isSupported() ? window.Notification : {};

const ServiceWorkerListener = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openWarning, setOpenWarning] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const snackBarAlignment = { vertical: 'top', horizontal: 'center' };
  const { registerPN } = useRegisterPushNotifications();
  const runOnce = useRef(false);
  const isLoggedIn = useLoggedIn();

  useEffect(() => {
    const handleSWMessage = event => {
      if (!event.data?.code) {
        if (window.location.pathname === '/') {
          navigate(0);
        } else {
          navigate('/');
        }
      }
    };
    navigator.serviceWorker?.addEventListener('message', handleSWMessage);

    return () => { navigator.serviceWorker?.removeEventListener('message', handleSWMessage); };
  }, [navigate]);

  useEffect(() => {
    if (!runOnce.current && isLoggedIn) {
      const { permission } = Notification;
      if (permission === 'denied' || permission === 'default') {
        setOpenWarning(true);
      } else {
        registerPN();
      }
      runOnce.current = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }
    return true;
  };

  const handlePermissions = async permission => {
    if (permission === 'granted') {
      setOpenWarning(false);
      const success = await registerPN();
      if (success) {
        setOpenSuccess(true);
      } else {
        setOpenError(true);
      }
    } else if (permission === 'denied') {
      setOpenWarning(false);
      setOpenError(true);
    }
  };

  const enableNotifications = async () => {
    let permission;
    if (checkNotificationPromise()) {
      permission = await Notification.requestPermission();
      handlePermissions(permission);
    } else {
      Notification.requestPermission(handlePermissions);
    }
  };

  return (
    <>
      <Snackbar open={openWarning} anchorOrigin={snackBarAlignment}>
        <Alert
          severity="info"
          action={(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button color="inherit" size="small" onClick={enableNotifications}>{t('clickToEnable')}</Button>
              <IconButton size="small" onClick={() => setOpenWarning(false)}><Close /></IconButton>
            </div>
        )}
        >{t('notificationsDisabled')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        anchorOrigin={snackBarAlignment}
        autoHideDuration={6000}
        onClose={() => setOpenError(false)}
      >
        <Alert severity="error" onClose={() => setOpenError(false)}>{t('unableToAllowNotifications')}</Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        anchorOrigin={snackBarAlignment}
        autoHideDuration={4000}
        onClose={() => setOpenSuccess(false)}
      >
        <Alert severity="success" onClose={() => setOpenSuccess(false)}>{t('notificationsEnabled')}</Alert>
      </Snackbar>
    </>
  );
};

export default ServiceWorkerListener;
